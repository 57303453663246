import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { ReactComponent as BackIcon } from "../assets/back.svg";
import RecipeForm from "../components/RecipeForm";
import Header from "../components/Header";

const EditRecipePage = () => {
  let { id: recipeId } = useParams();
  let navigate = useNavigate();

  // let recipe = recipes.find(recipe => recipe.id === Number(id))
  let [recipe, setRecipe] = useState(null);

  useEffect(() => {
    let getRecipe = async () => {
      return;
    };

    getRecipe();
  }, [recipeId]);

  return (
    <div className="wrapper">
      <Header title="Edit a Recipe" />
      <div className="recipe">
        <div className="recipe-header">
          <h3>
            <Link className="back-button" to="/">
              <BackIcon />
            </Link>
          </h3>
        </div>
        <RecipeForm recipe={recipe} />
      </div>
    </div>
  );
};

export default EditRecipePage;
